import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import capIcon from '../../images/SVG/c-black.svg'
import Fingerprint from 'fingerprintjs'
import PinInput from 'react-pin-input'
import { __ } from '../../l10n'

class Home extends Component {

	state = {
		link: null,
		loggedIn: false,
		modal: false,
		pin: '1708',
		lastError: null
	}

	constructor(props) {
		super(props)
		this.linkRef = React.createRef()
	}

	componentDidMount = () => {
		let qs = new URLSearchParams(window.location.search)
		if (qs.has('sess_key')) {
			let sess_key = qs.get('sess_key')
			window.history.pushState({}, 'CapPay', '/my/pos')
			window.sessionStorage.setItem('sess_key', sess_key)
		}

		if (window.sessionStorage.sess_key) {
			this.setState({ loggedIn: true })
		}
	}

	generateLink = () => {
		this.setState({ link: null, loading: true })

		const formData = {}
		
		formData.sess_key = window.sessionStorage.sess_key
		formData.pin = this.state.pin
		formData.publicKey = 'becaafb0-a021-11e9-9efb-2d7c49d415f2'
		formData.appid = 1
		formData.device = String(new Fingerprint({screen_resolution: true}).get())
		formData.permissions = 'list_invoices,view_invoice,create_invoice,cancel_invoice,view_wallets'.split(',')

		fetch('https://api.capitual.com/api/v1.0/session/createApiKey', { method: 'POST', headers: {'Content-Type': 'application/json; charset=UTF-8'}, body: JSON.stringify(formData) }).then(res => res.json()).then(res => {
			if (res.data) {
				this.setState({ link: 'https://pos.capitual.com/?sess_key=' + res.data, loading: false })
			} else if (res.error) {
				this.setState({ lastError: res.error, modal: true })
			}
		})
	}

	clipboard = () => {
		this.linkRef.current.focus()
		this.linkRef.current.select()
		window.document.execCommand('copy')
	}

	render() {
		return  (
			<Fragment>
				<div className="hero is-light animated fadeInDown">
			        <div className="hero-body">
			          <div className="container has-text-centered">
			            <h1 className="title is-2 is-spaced has-text-weight-semibold">{ __('pos.title') }</h1>

			            <h2 className="subtitle is-6 has-text-weight-light">{ __('pos.subtitle') }</h2>

			            </div>
			          </div>
			     </div>
			     <section className="section">
					<div className="container is-fluid">

						<div className='card animated fadeInDown'>
							<div className='card-content'>
								<p className='title is-4'>{ __('pos.download') }</p>
								<div className='columns'>
									<div className='column is-2'>
										<a href="https://play.google.com/store/apps/details?id=io.capitual.cappay">
											<img src={ __('pos.android') } style={{ width: '200px' }} />
										</a>
									</div>
									<div className='column is-2'>
										<a href="https://apps.apple.com/br/app/cappay/id1485795646">
											<img src={ __('pos.ios') } style={{ width: '175px', transform: 'translateY(10px)' }} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<br/>

						<div className='card animated fadeInDown'>
							<div className='card-content'>
								<p className='title is-4'>{ __('pos.online') }</p>
								<a href="https://pos.capitual.com">{ __('pos.web') }</a>
							</div>
						</div>

						<br/>

						<div className='card animated fadeInDown'>
							<div className='card-header'>
								<div className='card-header-title'>{ __('pos.link.title') }</div>
							</div>
							<div className='card-content'>
								<p>{ __('pos.link.text') }</p>

								<br/>

								{ !this.state.loggedIn ? 
									<a href='https://my.capitual.com/servicelogin/1?public=becaafb0-a021-11e9-9efb-2d7c49d415f2&perms=list_invoices,view_invoice,create_invoice,cancel_invoice,view_wallets,create_api_keys&return_url=https://cappay.capitual.com/my/pos' className='button is-rounded'>
										<img src={capIcon} style={{ height: '16px', marginRight: '9px' }} /> { __('buttongen.loginBtn') }
									</a>
									:
									<div className='field has-addons'>
										<div className='control'>
											<button className='button is-primary is-medium' onClick={() => this.setState({ modal: true })}>{ __('pos.link.generate') }</button>
										</div>
										<div className='control is-fullwidth'>
											<input className={`input is-medium ${this.state.loading && "is-loading"}`} value={this.state.link} readOnly ref={this.linkRef} />
										</div>
										<div className='control'>
											<button className='button is-info is-medium' disabled={!this.state.link} onClick={this.clipboard}>{ __('pos.link.copy') }</button>
										</div>
									</div>
								}
							</div>
						</div>

					</div>
				</section>
				<div className={`modal ${this.state.modal ? 'is-active' : ''}`}>
					<div class="modal-background"></div>
					<div class="modal-content">
						<div className='card'>
							<div className='card-header'>
								<div className='card-header-title'>{ __('pos.link.pin') }</div>
							</div>
							<div className='card-content has-text-centered'>
								{ this.state.lastError === 'WrongPin' &&
									<p class="is-warning">{ __('pos.link.wrongPin') }</p>
								}
								{ this.state.lastError === 'TooManyAttempts' &&
									<p class="is-error">{ __('pos.link.pinBlocked') }</p>
								}
								{ this.state.modal &&
									<PinInput
										length={4}
										type='numeric'
										secret
										focus
										onComplete={pin => this.setState({ pin, modal: false }, this.generateLink)}
									/>
								}
							</div>
						</div>
					</div>
					<button class="modal-close is-large" aria-label="close" onClick={() => this.setState({ modal: false })}></button>
				</div>
			</Fragment>
		)
	}
}

export default Home