import React from "react";
import { Element } from "react-scroll";
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade";
import { __ } from '../l10n'

const Documentation = () => (
    <Element
        name="Documentation"
        id="Documentation"
        className="hero is-light is-medium is-dark"
    >
        <div className="hero-body">
            <div className="container has-text-centered">
                <Fade top>
                    <p>
                        { __('landing.devdocs.title') }
                    </p>
                </Fade>

                <br />
                <Fade bottom>
                    <Link to="/my/docs"
                        className="button is-medium is-rounded"
                    >
                        { __('landing.devdocs.button') }
                    </Link>
                </Fade>
            </div>
        </div>
    </Element>
);

export default Documentation;
