import React, { Fragment } from "react";

import "../styles/styles.sass";

import Home from "../components/Home";
import Services from "../components/Services";
import Ecommerce from "../components/Ecommerce";
import Documentation from "../components/Documentation";
import Help from "../components/Help";
import Footer from "../components/Footer";

const Index = () => (
    <Fragment>
        <Home />
        <Services />
        <Ecommerce />
        <Documentation />
        <Help />
        <Footer />
    </Fragment>
);

export default Index;
