import React, { Fragment } from 'react'
import { __ } from '../../l10n'

import Markdown from "react-remarkable";

const Home = () => (
	<Fragment>
		<div className="hero is-light">
	        <div className="hero-body">
	          <div className="container has-text-centered">
	            <h1 className="title is-2 is-spaced has-text-weight-semibold">{ __('developers.title') }</h1>

	            <h2 className="subtitle is-6 has-text-weight-light">{ __('developers.subtitle') }</h2>

	            </div>
	          </div>
	     </div>
	     <section className="section">
			<div className="container is-fluid">
				<div className='card'>
					<div className='card-content'>
				<div className='content'>
				<Markdown>{`

### Authenticating

Sending CapPay invoices can be done securely either from the client side or the server side, as no authentication token is required.

Since Capitual API requires an authentication token to be provided, CapPay offers a safe way to store your authentication token used for generating invoices.

In order to generate an authentication token and start using CapPay, simply go to the "CapPay Button" page, on the sidebar, and click "Login with CapPay". That's all. Note that **you are required to do this procedure before start using CapPay**.

#### Finding your merchant ID

For generating invoices, you will need your merchant ID. You can either find it on "CapPay Button" page (it will show up as a hidden input named "merchant" on the button's provided code) or go to [your Capitual profile](https://my.capitual.com/account) and finding it near "Support ID". Note that your merchant ID is the numeric part of the support ID. I.e. if your support ID is CAP-U1234, then your merchant ID is 1234.

### Sending Invoices

Sending invoices can be done with a single HTTP request. It is safe to do this request from the server and from the client side.

Simply do a **POST** request to our endpoint at:

\`\`\`
POST https://pay.capitual.com/v1.0/create
\`\`\`

Your request shall contain an URL-encoded body (it's recommended to set the header \`Content-Type: application/x-www-form-urlencoded\`) with the following variables:

* **merchant**: Your merchant ID (see above).
* **wallet**: Capitual Wallet Address that will receive the payment (must be one of the wallets in your account).
* **currency**: Uppercase currency code for the invoice value (possible values are \`USD\`, \`EUR\`, \`BRL\`, \`BTC\`, \`LTC\` and \`DSH\`).
* **value**: String containing the value in the mentioned currency (e.g. \`100.50\`).
* **payee**: Email address of the payee.
* **expires** (optional): Unix Timestamp of the invoice's due date. Omit if you do not want your invoice to expire.
* **ipn** (optional): Instant Payment Notification URL (see below). Omit if you do not want to use IPN.
* **description** (optional): Human-readable payment description (visible for the payee).


#### Response 
The response follows the structure:

\`\`\`
{ error, data }
\`\`\`

In case of success, \`error\` is \`false\` and \`data\` contains the invoice payment URL you must redirect your user to.

Note that the payment URL follows the structure \`https://my.capitual.com/pay/<invoice ID>\`. Therefore, taking the URL basename gives you the actual invoice ID.

When redirecting the user to the payment URL, you can also provide a return URL which the user will be sent to after completing the payment. In order to do so, just add a query string param named \`return_url\` with the redirect URL.

\`\`\`
https://my.capitual.com/pay/<invoice ID>?return_url=https://yoursite.com/thanks
\`\`\`

In case of error, \`error\` will contain the message \`"MissingParameter"\` which means that you have omitted a non-optional variable from the list above.

### IPN (Instant Payment Notification)

If you provide a valid and publicly-accessible URL as IPN, you will receive POST HTTP requests regarding your transaction.

The requests include two URL-encoded variables:

* **InvoiceID**: the invoice ID (note that the invoice ID is part of the invoice URL, which is \`https://my.capitual.com/pay/<invoice ID>\`)
* **Type**: Message type, which can be (case-sensitive).

The message type may be (notice the case):

| Type | Description |
|---|---|
| \`Received\` | The invoice was paid, but not yet confirmed. <span style="color: orange">It's <b>not</b> yet safe to deliver the product/service. |
| \`Paid\` | The invoice was paid and confirmed. <span style="color: green">It's now safe to deliver the product/service.</span> |
| \`Cancelled\` | The invoice was cancelled (this can only be done by the invoice sender) |

#### Notes about IPN

* Use, as a URL query param, a secret access token that you can verify, at the time of the IPN request, whether this request came from Capitual or is being faked by an attacker. It's recommended to create an access token on a per-order basis.
* Our recommendation is **never to trust IPN requests only** and always use Capitual API for checking the status of the invoice. Use it exclusively as a trigger to perform the checks.
* If you are using buttons generated by the CapPay Button generator, or creating invoices at the client side, or making the IPN URL public by any other way, take our recommendation above as a must-do.

#### Testing Environment (Testnet)

Here are the host names you and your application are going to communicate with, during both testing and production phases. **Always use HTTPS.**

Description | Production | Testing
---|---|---
CapPay API | pay.capitual.com | testpay.capitual.com
Capitual front-end | my.capitual.com | testnet.capitual.com
Capitual API | api.capitual.com | apitestnet.capitual.com

Note that Capitual and Capitual Testnet are two disctinct installations of Capitual underlying software, and therefore you need to register at both. You can deposit testnet versions of the currencies or ask in the support for fake balance.

CapPay test endpoints only accept Capitual Testnet credentials.

### Getting Invoice Status

When you receive an IPN (or at any desired time), you can get the invoice status directly from CapPay servers through a **GET** request to:

\`\`\`
GET https://pay.capitual.com/v1.0/details/<invoice ID>
\`\`\`

#### Response 
The response follows the structure:

\`\`\`
{ error, data }
\`\`\`

In case of success, \`error\` is \`false\` and \`data\` contains the invoice data in the following way:

\`\`\`
{
	"error": false,
	"data": {
		"merchant": "",
		"wallet": "",
		"currency": "",
		"value": "",
		"expires": "",
		"ipn": "",
		"description": "",
		"status": ""
	}
}
\`\`\`

Where the values are:

| Value | Type | Description |Example
|---|---|---|---|
| merchant | integer | Invoice sender's merchant ID | 1234 |
| wallet | string | Receiving wallet's address | CAP-XXXXXXXX-XXXXXX-XX |
| currency | string | Invoice\'s currency code | BTC |
| value | string | Invoice\'s value | 100.00 |
| expires | integer or null | Invoice due date | UTC Unix Timestamp |
| ipn | string or null | IPN URL | https://yoursite.com/ipn |
| description | string | Invoice description | Payment for order 123 |
| status | string | Invoice status | "pending", "paid", "canceled" or "expired" |

Otherwise, the only possible error is "InvalidInvoice", which means that the invoice ID is not valid.

### More details

Since CapPay is built on the top of the Invoicing feature of Capitual, please refer to the [official documentation](https://dev.capitual.com/#invoices) for further details about listing, cancelling or re-sending invoices.
					`}</Markdown>
				</div>
				</div>
				</div>
			</div>
		</section>
	</Fragment>
)

export default Home