import React from 'react'
import _ from 'lodash'
import { RawHTML } from 'react-dom';
import parse from 'html-react-parser';
//import { sprintf } from 'sprintf-js'
import neon from 'neon-js'

const languages_neon = {
	pt: neon.decode(require('./pt').default).toObject(true),
	en: neon.decode(require('./en').default).toObject(true)
}

class i18n {

	constructor(ulang = false) {

		this.langs = languages_neon

		let savedLang = localStorage.getItem('locale')
		if (savedLang && !ulang) {
			this.ulang = savedLang
		} else {
			if (ulang && this.langs.hasOwnProperty(ulang))
				this.ulang = ulang
			else {
				window.navigator.languages.every(lang => {
					if (this.langs.hasOwnProperty(lang.substr(0, 2))) {
						this.ulang = lang.substr(0, 2)
						return false
					}
				})

				if (!this.ulang)
					this.ulang = this.getFallbackLanguage()
			}
		}
	}

	setLanguage(ulang) {
		if (ulang && this.langs.hasOwnProperty(ulang)) {
			this.ulang = ulang
			localStorage.setItem('locale', this.ulang)
		}
		else
			throw new Error("Invalid language", ulang)
	}

	getLanguage() {
		return this.ulang
	}

	getLanguages() {
		return this.languagesList
	}

	getFallbackLanguage() {
		return 'en'
	}

	__(key, args = {}) {
		let translate = _.get(this.langs[this.ulang], key, key)
		//return sprintf(translate, args)

		// parsing
		translate = translate.replace(/\*\*(\S(.*?\S)?)\*\*/gm, '<b>$1</b>')
		translate = translate.replace(/\_(\S(.*?\S)?)\_/gm, '<i>$1</i>')

		return parse(translate)
	}

	__p(num, if1, if2) {
		return num===1 ? if1 : if2
	}
}

let instance = new i18n()

let __ = (key, args) => instance.__(key, args)

export default instance

export { __ }