import React, { Fragment } from "react";
import {Switch, Route, BrowserRouter} from 'react-router-dom'

import Index from './pages/Index'
import My from './pages/My'

const App = () => (
	<BrowserRouter>
	    <Switch>
	    	<Route exact path="/" component={Index} />
	    	<Route path="/my" component={My} />
	    	<Route component={Index} />
	    </Switch>
    </BrowserRouter>
);

export default App;
