import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { __ } from '../../l10n'

const Home = () => (
	<Fragment>
		<div className="hero is-light animated fadeInDown">
	        <div className="hero-body">
	          <div className="container has-text-centered">
	            <h1 className="title is-2 is-spaced has-text-weight-semibold">{ __('my.title') }</h1>

	            <h2 className="subtitle is-6 has-text-weight-light">{ __('my.subtitle') }</h2>

	            </div>
	          </div>
	     </div>
	     <section className="section">
			<div className="container is-fluid">

				<Link to="/my/button">
					<div className='card animated fadeInDown'>
						<div className='card-content'>
							<div className='media'>
								<div className='media-left'>
									<p className='title is-color-primary is-1'><i className='fal fa-shopping-bag' /></p>
								</div>
								<div className='media-content'>
									<p className='title is-4'>{ __('my.button1.title') }</p>
									<p className='title is-6'>{ __('my.button1.text') }</p>
								</div>
							</div>
						</div>
					</div>
				</Link>

				<br/>

				<Link to="/my/pos">
					<div className='card animated fadeInDown'>
						<div className='card-content'>
							<div className='media'>
								<div className='media-left'>
									<p className='title is-color-primary is-1'><i className='fal fa-shopping-cart' /></p>
								</div>
								<div className='media-content'>
									<p className='title is-4'>{ __('my.button2.title') }</p>
									<p className='title is-6'>{ __('my.button2.text') }</p>
								</div>
							</div>
						</div>
					</div>
				</Link>

				<br/>

				<Link to="/my/docs">
					<div className='card animated fadeInDown'>
						<div className='card-content'>
							<div className='media'>
								<div className='media-left'>
									<p className='title is-color-primary is-1'><i className='fal fa-code' /></p>
								</div>
								<div className='media-content'>
									<p className='title is-4'>{ __('my.button3.title') }</p>
									<p className='title is-6'>{ __('my.button3.text') }</p>
								</div>
							</div>
						</div>
					</div>
				</Link>

			</div>
		</section>
	</Fragment>
)

export default Home