import React from "react";
import { Element } from "react-scroll";
import Fade from "react-reveal/Fade";
import { __ } from '../l10n'

import serviceCurrency from "../images/SVG/accept-fiat-cripto-currencies.svg";
import serviceEasy from "../images/SVG/easy-to-get-started.svg";
import serviceOnline from "../images/SVG/whether-you-are-online-physical.svg";
import serviceReceive from "../images/SVG/receive-as-you-want.svg";

let ITEMS = [
    {
        title: __('landing.about.item1.title'),
        image: serviceCurrency,
        description: __('landing.about.item1.text')
    },
    {
        title: __('landing.about.item2.title'),
        image: serviceReceive,
        description: __('landing.about.item2.text')
    },
    {
        title: __('landing.about.item3.title'),
        image: serviceOnline,
        description: __('landing.about.item3.text')
    },
    {
        title: __('landing.about.item4.title'),
        image: serviceEasy,
        description: __('landing.about.item4.text')
    }
];

const Services = () => (
    <Element name="Services" id="Services" className="hero is-medium is-white">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-multiline">
                    <div className="column is-12">
                        <div className="columns">
                            <div className="column is-5">
                                <Fade top>
                                    <h4 className="title is-3">
                                        { __('landing.about.title') }
                                    </h4>
                                </Fade>

                                <p>
                                    { __('landing.about.sub') }
                                </p>
                            </div>

                            <hr style={{ backgroundColor: "white" }} />
                        </div>
                    </div>
                    <div className="column is-12">
                        <div className="columns is-multiline">
                            {ITEMS.map((item, index) => (
                                <div className="column is-6">
                                    <div className="columns">
                                        <div className="column">
                                            <Fade top delay={index * 300}>
                                                <figure className="image">
                                                    <img
                                                        src={item.image}
                                                        className="is-fullwidth"
                                                    />
                                                </figure>
                                            </Fade>
                                        </div>
                                        <div className="column is-9">
                                            <h4 className="title is-6">
                                                {item.title}
                                            </h4>
                                            <p>
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Element>
);

export default Services;
