import React from "react";
import { Link } from 'react-router-dom'
import { Element } from "react-scroll";
import Fade from "react-reveal/Fade";

// assets
import appScreen from "../images/1x/app-screen.png";
import merchant from '../images/SVG/merchant.svg';
import googlePlay from "../images/SVG/google-play.svg";
import appleStore from "../images/SVG/apple-store.svg";

// components
import NavBar from "../components/Navbar";

import { __ } from '../l10n'

const Home = () => (
    <Element name="Home" id="Home" className="hero is-fullheight is-dark">
        <div className="hero-head">
            <div className="container is-fluid">
                <NavBar />
            </div>
        </div>

        <a className="more" />

        <div className="hero-body">
            <div className="container">
                <div className="columns is-vcentered is-centered">
                    <div className="column is-auto has-text-right">
                        <Fade left>
                            <h1 className="title is-1">
                                { __('landing.home.hero') }
                            </h1>
                        </Fade>
                    </div>
                    <Fade bottom>
                        <div className="column is-3 has-text-centered is-hidden-touch">
                            <img src={appScreen} alt="" />
                        </div>
                    </Fade>

                    <Fade right>
                        <div className="column is-auto">
                            <div className="line" />

                            <h2 className="subtitle is-3">
                                { __('landing.home.subhero') }
                            </h2>

                            <div className="columns is-multiline has-text-left">
                                <div className="column is-12">
                                   <Link to="/my" className="button is-large is-disabled">
                                        <div className="icon">
                                            <img
                                                src={merchant}
                                                width="20"
                                                alt=""
                                            />
                                        </div>
                                        { __('landing.home.merchantsBtn') }
                                    </Link>
                                    <br />
                                    <br />
                                    <br />
                                    <a href="https://play.google.com/store/apps/details?id=io.capitual.cappay" className="button">
                                        <div className="icon">
                                            <img
                                                src={googlePlay}
                                                width="20"
                                                alt=""
                                            />
                                        </div>
                                        { __('landing.home.android') }
                                    </a>
                                    <br />
                                    <br />
                                    <a href="https://apps.apple.com/br/app/cappay/id1485795646" className="button">
                                        <div className="icon">
                                            <img
                                                src={appleStore}
                                                width="20"
                                                style={{
                                                    transform:
                                                        "translateY(-4px)"
                                                }}
                                                alt=""
                                            />
                                        </div>
                                        { __('landing.home.ios') }
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    </Element>
);

export default Home;
