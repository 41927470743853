import React from "react";
import { Element } from "react-scroll";
import { __ } from '../l10n'

import magento from "../images/SVG/magento.svg";
import opencart from "../images/SVG/opencart.svg";
import woocommerce from "../images/SVG/woocommerce.svg";
import Fade from "react-reveal/Fade";

const Ecommerce = () => (
    <Element
        name="Ecommerce"
        id="Ecommerce"
        className="hero is-light is-medium is-white"
    >
        <div className="hero-body">
            <div className="container">
                <div className="columns is-vcentered">
                    <div className="column is-6 has-text-left">
                        <h2 className="title is-1">
                            { __('landing.ecommerce.title') }
                        </h2>
                        <p style={{ maxWidth: "80%" }}>
                            { __('landing.ecommerce.text') }
                        </p>
                    </div>

                    <div className="column is-4">
                        <Fade top delay={300}>
                            <a href="https://capitual.github.io/cappay-magento" target="_blank">
                                <div className="platform">
                                        <img src={magento} alt="" />
                                </div>
                            </a>
                        </Fade>
                        <Fade top delay={600}>
                            <a href="https://capitual.github.io/cappay-opencart" target="_blank">
                                <div className="platform">
                                    <img src={opencart} alt="" />
                                </div>
                            </a>
                        </Fade>
                        <Fade top delay={900}>
                            <a href="https://capitual.github.io/cappay-woocommerce" target="_blank">
                                <div className="platform">
                                    <img src={woocommerce} alt="" />
                                </div>
                            </a>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    </Element>
);

export default Ecommerce;
