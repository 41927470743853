import React from "react";
import { Element } from "react-scroll";
import { Link as LinkScroll } from "react-scroll";
import { __ } from '../l10n'

const Link = ({ to, ...rest }) => (
    <LinkScroll to={to} activeClass="is-active" spy smooth>
        {rest.children}
    </LinkScroll>
);

import logo from "../images/SVG/logo-black.svg";

const Help = () => (
    <Element name="Footer" id="Footer" className="hero is-light is-small">
        <div className="hero-body">
            <div className="container">
                <hr
                    style={{
                        backgroundColor: "#E6E6E6",
                        height: 2
                    }}
                />
                <nav class="navbar is-black">
                    <div class="navbar-brand">
                        <a class="navbar-item" href="/">
                            <img
                                src={logo}
                                alt="CapPay"
                                width="112"
                                height="28"
                            />
                        </a>
                    </div>

                    <div class="navbar-menu">
                        <div class="navbar-end">
                            <div class="navbar-item">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <span>{ __('landing.copyright.pre') }</span>
                                    <span
                                        style={{
                                            color: "#FA6464",
                                            fontSize: 40,
                                            margin: "0 10px"
                                        }}
                                    >
                                        ♥
                                    </span>
                                    <span>{ __('landing.copyright.post') }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </Element>
);

export default Help;
