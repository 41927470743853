import React from "react";
import { Element } from "react-scroll";
import { Link as LinkScroll } from "react-scroll";
import Fade from "react-reveal/Fade";
import { __ } from '../l10n'

const Link = ({ to, ...rest }) => (
    <LinkScroll to={to} activeClass="is-active" spy smooth>
        {rest.children}
    </LinkScroll>
);

import stackIcon from "../images/SVG/stack.svg";
import slackIcon from "../images/SVG/slack.svg";
import telegramIcon from "../images/SVG/telegram.svg";

import logo from "../images/SVG/logo-white.svg";

const Help = () => (
    <Element name="Help" id="Help" className="hero is-light is-medium is-light">
        <div className="hero-body">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <Fade top>
                            <h1 className="title is-1">
                                { __('landing.help.title') }
                            </h1>
                        </Fade>
                        <Fade bottom>
                            <small>
                                { __('landing.help.text') }
                            </small>
                        </Fade>
                    </div>
                    <div className="column">
                        <div className="container is-fluid">
                            <a href="https://stackoverflow.com/questions/tagged/capitual" target="_blank" className="button is-rounded">
                                <div className="icon">
                                    <img src={stackIcon} width="15" alt="" />
                                </div>
                                <span>Stackoverflow</span>
                            </a>
                            <br />

                            <a href="https://capitual.slack.com/" target="_blank" className="button is-rounded">
                                <div className="icon">
                                    <img src={slackIcon} width="15" alt="" />
                                </div>
                                <span>Slack</span>
                            </a>
                            <br />
                            <a href="https://t.me/capitual" target="_blank" className="button is-rounded">
                                <div className="icon">
                                    <img src={telegramIcon} width="15" alt="" />
                                </div>
                                <span>Telegram</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Element>
);

export default Help;
