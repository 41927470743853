import React from "react";
import { Link, Route } from "react-router-dom";
import { __ } from '../../l10n'

import 'animate.css'
import "../../styles/sass/app.sass";

import logo from "../../images/SVG/logo-black.svg";

// subpages
import Home from "./Home";
import Dev from './Dev'
import Button from './Button'
import ButtonTestnet from './ButtonTestnet'

import Pos from './Pos'
import SdkPhp from './SdkPhp'

class My extends React.Component {
  state = {}
  
  render() {
	return (
	  <React.Fragment>
		<div
		  className="mobile-burger burger is-hidden-desktop"
		  data-target="#doc-menu"
		>
		  <span></span>
		  <span></span>
		  <span></span>
		</div>
		<div className="columns is-gapless">
		  <div
			id="doc-menu"
			className="column is-2 has-background-grey-lighter menu is-mobile"
		  >
			<section className="section">
				<Link to="/">
			  		<img src={logo} alt="CapPay" />
			  	</Link>
			</section>

			<ul className="menu-list">
			  <li>
				<Link className="menu-item" to="/my">
				  { __('my.menu.home') }
				</Link>
			  </li>

			  <li>
				<Link className="menu-item" to="/my/pos">
				  { __('my.menu.pos') }
				</Link>
			  </li>

			  <li>
				<Link className="menu-item" to="/my/button">
				  { __('my.menu.button') }
				</Link>
			  </li>

			  <li className={"menu-item has-dropdown " + (this.state.menuSdk && "is-active")}>
				<a
				  className="menu-title"
				  onClick={() =>
					this.setState({ menuSdk: !this.state.menuSdk })
				  }
				>
				  { __('my.menu.sdk') }
				  <span className="menu-caret"></span>
				</a>
				<ul className="menu-dropdown">
				  <li>
					<Link className="menu-item" to="/my/php-sdk">
					  PHP
					</Link>
				  </li>
				  {/*<li>
					<a
					  className="menu-item"
					  href="http://github.com/Capitual/node-cappay/blob/master/README.md"
					>
					  NodeJS
					</a>
				  </li>*/}
				</ul>
			  </li>

			  {/*<li
				className={"menu-item has-dropdown " + (this.state.menuPlugins && "is-active")}
			  >
				<a
				  className="menu-title"
				  onClick={() =>
					this.setState({ menuPlugins: !this.state.menuPlugins })
				  }
				>
				  Plugins
				  <span className="menu-caret"></span>
				</a>
				<ul className="menu-dropdown">
				  <li>
					<a
					  className="menu-item"
					  href="http://localhost:4000/#features"
					>
					  Magento
					</a>
				  </li>
				  <li>
					<a
					  className="menu-item"
					  href="http://localhost:4000/#content"
					>
					  Woocommerce
					</a>
				  </li>
				  <li>
					<a
					  className="menu-item"
					  href="http://localhost:4000/#content"
					>
					  OpenCart
					</a>
				  </li>
				</ul>
			  </li>*/}

				<li>
				  <Link className="menu-item" to="/my/docs">
					{ __('my.menu.dev') }
				  </Link>
				</li>
			</ul>
		  </div>
		  <div className="column">
			<Route exact path="/my" component={Home} />
			<Route exact path="/my/pos" component={Pos} />
			<Route exact path="/my/docs" component={Dev} />
			<Route exact path="/my/button" component={Button} />
			<Route exact path="/my/button/testnet" component={ButtonTestnet} />
			<Route exact path="/my/php-sdk" component={SdkPhp} />
		  </div>
		</div>
	  </React.Fragment>
	);
  }
}

export default My;
