import React, { Fragment } from 'react'
import capIcon from '../../images/SVG/c-black.svg'
import { __ } from '../../l10n'
import { Link } from 'react-router-dom'
import 'url-search-params-polyfill'

class Buttongen extends React.Component {

	state = {
		tab: 'style',
		verb: 'pay',
		lang: 'en',
		theme: 'light',
		layout: 'hor',
		coins: 'coins',
		size: 80,
		loggedIn: false,
		loading: false,
		wallets: [],
		currencies: []
	}

	componentDidMount = () => {
		let qs = new URLSearchParams(window.location.search)
		if (qs.has('sess_key')) {
			let sess_key = qs.get('sess_key')
			window.history.pushState({}, 'CapPay', '/my/button')
			window.sessionStorage.setItem('sess_key', sess_key)
		}

		if (window.sessionStorage.sess_key) {
			this.setState({
				loading: true
			}, () => {
				// TO-DO: save session key

				// get user wallets
				fetch('https://api.capitual.com/v1.0/wallets/getUserWallets', { method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}, body: 'sess_key='+window.sessionStorage.sess_key }).then(res => res.json()).then(res => {
					if (res.data) {
						this.setState({ wallets: res.data, loggedIn: true, loading: false })
					}
				})

				// get currencies
				fetch('https://api.capitual.com/v1.0/currencies/getCurrencies', { method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}, body: 'sess_key='+window.sessionStorage.sess_key }).then(res => res.json()).then(res => {
					if (res.data) {
						this.setState({ currencies: res.data })
					}
				})

				// get user data
				fetch('https://api.capitual.com/v1.0/session/getUserData', { method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}, body: 'sess_key='+window.sessionStorage.sess_key }).then(res => res.json()).then(res => {
					if (res.data) {
						fetch('https://pay.capitual.com/v1.0/SaveAuthToken', { method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}, body: 'sess_key='+window.sessionStorage.sess_key+'&uid='+res.data.id })
						this.setState({ udata: res.data })
					}
				})
			})
		}
	}

	render() {
		let buttonUrl = 'https://static.capitual.net/cappay/btn/' + this.state.verb + '-' + this.state.lang + '-' + this.state.theme + '-' + this.state.layout + '-' + this.state.coins + '.svg'

		return (
			<Fragment>
				<div className="hero is-light">
			        <div className="hero-body">
			          <div className="container has-text-centered">
			            <h1 className="title is-2 is-spaced has-text-weight-semibold">{ __('buttongen.title') }</h1>

			            <h2 className="subtitle is-6 has-text-weight-light">{ __('buttongen.subtitle') }</h2>

			            </div>
			          </div>
			     </div>

			     <section className="section">
					<div className="container is-fluid">

						<div className='card'>

							{ !this.state.loggedIn &&
							<div className='hero is-light' style={{ height: '200px' }}>
								<div className='hero-body'>
									<div className='container'>
										<h2 className='subtitle'>{ __('buttongen.login') }</h2>
										<a href='https://my.capitual.com/servicelogin/1?public=becaafb0-a021-11e9-9efb-2d7c49d415f2&perms=list_invoices,view_invoice,create_invoice,cancel_invoice,view_wallets,create_api_keys&return_url=https://cappay.capitual.com/my/button' className='button is-rounded'>
											<img src={capIcon} style={{ height: '16px', marginRight: '9px' }} /> { __('buttongen.loginBtn') }
										</a>
										<Link to="/my/button/testnet" className='is-pulled-right'>{ __('buttongen.testnet') }</Link>
									</div>
								</div>
							</div>
							}

							{ this.state.loading && <i class="fal fa-spinner fa-pulse"></i> }

							<div className={'card-content btngen '+(this.state.loading || !this.state.loggedIn && "is-logged-out")}>
								<div className='columns'>
									<div className='column is-3'>

									<div className='tabs'>
										<ul>
											<li className={this.state.tab === 'style' && 'is-active'} onClick={() => this.setState({ tab: "style" })}><a>{ __('buttongen.style.tab') }</a></li>
											<li className={this.state.tab === 'payment' && 'is-active'} onClick={() => this.setState({ tab: "payment" })}><a>{ __('buttongen.payment.tab') }</a></li>
											<li className={this.state.tab === 'advanced' && 'is-active'} onClick={() => this.setState({ tab: "advanced" })}><a>{ __('buttongen.advanced.tab') }</a></li>
										</ul>
									</div>

									{ this.state.tab === 'style' &&
										<React.Fragment>
											<div className='field'>
												<div className='control'>
													<label className='radio'>
														<input type='radio' name='verb' value="pay" checked={this.state.verb === 'pay'} onChange={e => this.setState({ verb: e.target.value })} />
														{" "} { __('buttongen.style.pay') }
													</label>
													<br/>
													<label className='radio'>
														<input type='radio' name='verb' value="donate" checked={this.state.verb === 'donate'} onChange={e => this.setState({ verb: e.target.value })} />
														{" "} { __('buttongen.style.donate') }
													</label>
												</div>
											</div>

											<div className='field'>
												<label className='label'>{ __('buttongen.style.language') }</label>
												<div className='select is-fullwidth'>
													<select value={this.state.lang} onChange={e => this.setState({ lang: e.target.value })}>
														<option value="en">English</option>
														<option value="pt">Português</option>
													</select>
												</div>
											</div>

											<div className='field'>
												<label className='label'>{ __('buttongen.style.layout') }</label>
												<div className='select is-fullwidth'>
													<select value={this.state.layout} onChange={e => this.setState({ layout: e.target.value })}>
														<option value="hor">{ __('buttongen.style.horizontal') }</option>
														<option value="ver">{ __('buttongen.style.vertical') }</option>
													</select>
												</div>
											</div>

											<div className='field'>
												<label className='label'>{ __('buttongen.style.theme') }</label>
												<div className='select is-fullwidth'>
													<select value={this.state.theme} onChange={e => this.setState({ theme: e.target.value })}>
														<option value="light">{ __('buttongen.style.light') }</option>
														<option value="dark">{ __('buttongen.style.dark') }</option>
													</select>
												</div>
											</div>

											<div className='field'>
												<label className='label'>{ __('buttongen.style.size') }</label>
												<div className='control'>
													<input type='range' className='is-fullwidth' value={this.state.size} min={30} max={180} onChange={e => this.setState({ size: e.target.value })} />
												</div>
											</div>

											<div className='field'>
												<div className='control'>
													<label className='checkbox'>
														<input type='checkbox' checked={this.state.coins === 'coins'} onChange={e => this.setState({ coins: e.target.checked ? 'coins' : 'single' })} />
														{" "} { __('buttongen.style.includeSymbols') }
													</label>
												</div>
											</div>
										</React.Fragment>
									}

									{ this.state.tab === 'payment' &&
										<React.Fragment>
											<div className='field'>
												<label className='label'>{ __('buttongen.payment.receiving') }</label>
												<div className='select is-fullwidth'>
													<select value={this.state.wallet} onChange={e => this.setState({ wallet: e.target.value })}>
														<option />
														{ this.state.wallets.map(item =>
															<option value={item.wallet_address}>{item.wallet_name}</option>
														)}
													</select>
												</div>
											</div>

											<div className='field'>
												<label className='label'>{ __('buttongen.payment.invoiceCurrency') }</label>
												<div className='select is-fullwidth'>
													<select value={JSON.stringify(this.state.currency)} onChange={e => this.setState({ currency: JSON.parse(e.target.value) })}>
														<option />
														{ this.state.currencies.map(item =>
															<option value={JSON.stringify(item)}>{item.name} ({item.iso_code})</option>
														)}
													</select>
												</div>
											</div>

											<div className='field'>
												<label className='label'>{ __('buttongen.payment.invoiceValue') }</label>
												<p class='control has-icons-left'>

													<input
										              className="input amount"
										              readOnly={!this.state.currency}
										              step={this.state.currency && Math.pow(10, this.state.currency.precision*-1).toFixed(this.state.currency.precision)}
										              value={String(this.state.amount).replace(/(^0)([1-9]+)/g, '$2')}
										              onChange={e => this.setState({ amount: e.target.value })}
										              onBlur={e => this.setState({ amount: parseFloat(Math.abs(e.target.value)).toFixed(this.state.currency ? this.state.currency.precision : 0) })}
										              type="number"
										            />

													<span className='icon is-small is-left'>
														{this.state.currency && this.state.currency.symbol}
													</span>
												</p>
											</div>

											<hr />

											<div className='field'>
												<label className='label'>{ __('buttongen.payment.payee') }</label>
												<input className='input't type='text' value={this.state.payee} onChange={e => this.setState({ payee: e.target.value })} />
												<p className='help'>{ __('buttongen.payment.payee_helper') }</p>
											</div>

										</React.Fragment>
									}

									{ this.state.tab === 'advanced' &&
										<React.Fragment>
											<div className='field'>
												<label className='label'>{ __('buttongen.advanced.description') }</label>
												<textarea className='textarea' value={this.state.description} onChange={e => this.setState({ description: e.target.value })} />
											</div>

											<div className='field'>
												<label className='label'>{ __('buttongen.advanced.dueDate') }</label>
												<input className='input' type='date' value={this.state.expires} onChange={e => this.setState({ expires: e.target.value })} />
											</div>

											<hr />

											<div className='field'>
												<label className='label'>{ __('buttongen.advanced.ipn') }</label>
												<input className='input' ype='text' value={this.state.ipn} onChange={e => this.setState({ ipn: e.target.value })} />
												<p className='help'>{ __('buttongen.advanced.ipnHelper') }</p>
											</div>
										</React.Fragment>
									}
									</div>
									<div className='column is-9'>
										<img src={ buttonUrl } style={{ height: this.state.size+'px', cursor: 'pointer' }} />
										<hr/>
										<p>{ __('buttongen.copy') }</p>
										<figure className="lightlight">
											<pre>
												{'<form method="post" action="https://pay.capitual.com/v1.0/pay">\n' 
													+'\t<input type="hidden" name="merchant" value="' + (this.state.udata ? this.state.udata.id : "") + '" />\n'
													+'\t<input type="hidden" name="currency" value="' + (this.state.currency ? this.state.currency.iso_code : "") + '" />\n'
													+'\t<input type="hidden" name="wallet" value="' + (this.state.wallet || "") + '" />\n'
													+'\t<input type="hidden" name="value" value="' + (this.state.amount || "") + '" />\n'
													+'\t<input type="hidden" name="payee" value="' + (this.state.payee || "") + '" />\n'
													+'\t<input type="hidden" name="expires" value="' + (Math.floor(new Date(this.state.expires).getTime()/1000) || "") + '" />\n'
													+'\t<input type="hidden" name="ipn" value="' + (this.state.ipn || "") + '" />\n'
													+'\t<input type="hidden" name="description" value="' + (this.state.description || "") + '" />\n'
													+'\t<input\n\t\ttype="image"\n\t\tsrc="'+buttonUrl+'"\n\t\tstyle="cursor: pointer; height: '+this.state.size+'px" />\n'
												 +'</form>'}
											</pre>
										</figure>
									</div>
								</div>
							</div>
						</div>

					</div>
				</section>
			</Fragment>
		)
	}
}

export default Buttongen