import React, { Fragment } from "react";
import { Link as LinkScroll } from "react-scroll";
import { __ } from '../l10n'

const Link = ({ to, ...rest }) => (
    <LinkScroll
        activeClass="is-active"
        onClick={rest.onClick}
        to={to}
        spy
        smooth
    >
        {rest.children}
    </LinkScroll>
);

import logo from "../images/SVG/logo-white.svg";

export default class NavBar extends React.Component {
    state = {
        menuIsDark: false,
        menuOpen: false
    };

    componentDidMount = () => {
        window.addEventListener("scroll", this.setVisible);
    };

    setVisible = () => {
        const offset = document.documentElement.scrollTop + 75;
        if (offset >= window.innerHeight) {
            this.setState({ menuIsDark: true });
        } else {
            this.setState({ menuIsDark: false });
        }
    };

    toggleMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    render = () => (
        <Fragment>
            <input
                type="checkbox"
                id="menu-checkbox"
                ref={ref => (this.checkbox = ref)}
                checked={this.state.menuOpen}
            />
            <label
                onClick={this.toggleMenu}
                htmlFor="menu-checkbox"
                className={`menu-trigger ${this.state.menuIsDark && "is-dark"}`}
            >
                <span />
            </label>

            <div className="menu-trigger-content">
                <div className="content">
                    <ul>
                        <Link to="Home" onClick={this.toggleMenu}>
                            { __('landing.menu.home') }
                        </Link>
                        <Link to="Services" onClick={this.toggleMenu}>
                            { __('landing.menu.why') }
                        </Link>
                        <Link to="Ecommerce" onClick={this.toggleMenu}>
                            { __('landing.menu.ecommerce') }
                        </Link>
                        <Link to="Documentation" onClick={this.toggleMenu}>
                            { __('landing.menu.dev') }
                        </Link>
                        <Link to="Help" onClick={this.toggleMenu}>
                            { __('landing.menu.help') }
                        </Link>
                    </ul>
                </div>
            </div>

            <nav class="navbar is-black" ref={ref => (this.menu = ref)}>
                <div class="navbar-brand">
                    <a class="navbar-item" href="/">
                        <img
                            src={logo}
                            alt="CapPay"
                            width="112"
                            height="28"
                        />
                    </a>
                </div>
            </nav>
        </Fragment>
    );
}
